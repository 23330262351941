<template>
  <main>
      <headerElem></headerElem>
    <p>Notre opération est à présent terminé</p>
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
export default {
  name: 'finished',
      components: {
    HeaderElem,
  },
}
</script>